export interface EventNode {
  startDate: string
  orgConfig: {
    name: string
  }
  unixStartDate: string
  shortCode: string
  status: string
  id: string
  title: string
  actionNetworkBrowserUrl: string
  description: string
}

const electoralEventFilters: { (event: EventNode): boolean }[] = [
  event =>
    event.orgConfig.name !== "Campaign: Marcela" ||
    event.unixStartDate.startsWith("Mon") ||
    event.unixStartDate.startsWith("Sat") ||
    event.unixStartDate.startsWith("Sun"),
  event =>
    event.orgConfig.name !== "Campaign: Phara" ||
    event.unixStartDate.startsWith("Tue") ||
    event.unixStartDate.startsWith("Sat") ||
    event.unixStartDate.startsWith("Sun"),
  event =>
    event.orgConfig.name !== "Campaign: Jabari" ||
    event.unixStartDate.startsWith("Wed") ||
    event.unixStartDate.startsWith("Sat") ||
    event.unixStartDate.startsWith("Sun"),
  event =>
    event.orgConfig.name !== "Campaign: Zohran" ||
    event.unixStartDate.startsWith("Thu") ||
    event.unixStartDate.startsWith("Sat") ||
    event.unixStartDate.startsWith("Sun"),
]

export const electoralEventFilter = (event: EventNode): boolean =>
  electoralEventFilters.every(filter => filter(event))

export default electoralEventFilter
