/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Loadable from "react-loadable"
import { graphql, PageRendererProps } from "gatsby"
import moment from "moment"
import { EventInput } from "@fullcalendar/core"
import { Layout } from "../components/Layout"
import { eventCheckInPath } from "../util/paths"
import electoralEventFilter, { EventNode } from "../util/eventFilters"
import { earlyCutoff, lateCutoff } from "../util/dates"

interface Props extends PageRendererProps {
  data: {
    allActionNetworkEvent: {
      nodes: EventNode[]
    }
    allAirtable: {
      nodes: any[]
    }
  }
}

const withinCutoffMonths = (event: EventNode): boolean => {
  return moment(new Date(event.startDate)).isBetween(
    earlyCutoff,
    lateCutoff,
    "months"
  )
}

// TODO: perhaps just manually delay loading unless in browser env; maybe do some responsive stuff here too
const LoadableCalendar = Loadable({
  loader: () => import("../components/Calendar"),
  render: ({ Calendar }, props) => {
    return <Calendar {...props} />
  },
  loading() {
    return <div>Loading...</div>
  },
  delay: 500,
})

const CalendarIndex: React.FC<Props> = ({ data }) => {
  const {
    allActionNetworkEvent: { nodes: events },
    // allAirtable: { nodes: airtableEvents },
  } = data
  // FIXME: disabling airtable
  const airtableEvents: any[] = []

  // Filter + map events
  const actionNetworkCalEvents: EventInput[] = events
    .filter(withinCutoffMonths)
    .filter(electoralEventFilter)
    .map(event => {
      return {
        actionNetworkBrowserUrl: event.actionNetworkBrowserUrl,
        checkinPath: eventCheckInPath(event.shortCode),
        allDay: false,
        description: event.description,
        start: new Date(event.startDate),
        title: event.title,
      }
    })

  const airtableCalEvents: EventInput[] = airtableEvents.map(event => ({
    actionNetworkBrowserUrl: event.data.prefill_url?.[0],
    checkinPath: "",
    allDay: false,
    description: event.data.info?.[0],
    start: new Date(event.data.datestamp?.[0]),
    title: event.data.name,
  }))

  const calEvents = [...actionNetworkCalEvents, ...airtableCalEvents]

  return (
    <Layout wide title="Calendar">
      <LoadableCalendar events={calEvents} />
    </Layout>
  )
}

export default CalendarIndex

export const pageQuery = graphql`
  query CalendarQuery {
    allActionNetworkEvent(filter: { status: { eq: "confirmed" } }) {
      nodes {
        startDate
        unixStartDate: startDate(formatString: "ddd MMM DD HH:mm:ss z YYYY")
        orgConfig {
          name
        }
        shortCode
        status
        id
        title
        actionNetworkBrowserUrl: browserUrl
        # Unused
        description
        name
      }
    }
    # allAirtable {
    #   nodes {
    #     data {
    #       prefill_url
    #       datestamp
    #       info
    #       name
    #     }
    #   }
    # }
  }
`
